
import { Phone, Email, Instagram } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { PrimaryButton } from '../components/CustomMUI/CustomButtons';
import CustomTextField from '../components/CustomMUI/CustomTextField';
import LocationOnIcon from '@mui/icons-material/LocationOn'; 

const Contact = () => {
  const telephone = `+94702112112`
  const showTelephone = `070 211 2112`
  return (
    <div className="container my-5">
      <h1 className="pt-5 mb-5 page-title">Contact Us</h1>
      <div className="card">
        <div className="row">
          <div className="col-xl-4 d-flex justify-content-between flex-column">
            <h3>Fill up the form, and our Team will get back to you within 24 hours</h3>
            <div>
              <a href={`tel:${telephone}`} className="my-5">
                <Phone /> {showTelephone}
              </a>
              <br />
              <a href="mailto:info@slingmobility.com" className="my-5">
                <Email /> info@slingmobility.com
              </a>
              <p>
              <LocationOnIcon /> No. 84 Srimath Anagarika Dharmapala Mawatha, Colombo 00700, SriLanka
              </p>
            </div>
            <div className="d-flex justify-content-around">
              <IconButton href="https://en-gb.facebook.com/slingmobility/" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://lk.linkedin.com/company/slingmobility" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon />
              </IconButton>
              <IconButton href="https://www.instagram.com/slingmobility/" target="_blank" rel="noopener noreferrer">
                <Instagram />
              </IconButton>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="form-wrap">
              <form
                id="fs-frm"
                name="complaint-form"
                acceptCharset="utf-8"
                action="https://formspree.io/f/mpzgoleq" // Updated Formspree endpoint
                method="post"
              >
                <fieldset id="fs-frm-inputs">
                  <CustomTextField
                    label="Full Name"
                    placeholder="First and Last"
                    name="name"
                  />
                  <div className="row">
                  <div className="col-xl-6">
                  <CustomTextField
                    label="Email Address"
                    placeholder="email@domain.tld"
                    name="_replyto"
                  />
                  </div>
                  <div className="col-xl-6">
                  <CustomTextField
                    label="Telephone Number (Optional)"
                    placeholder="(555) 555-5555"
                    name="telephone"
                  />
                  </div>
                  </div>
                  <div className='mb-3'>
                  <CustomTextField
                    label="Write Your Message Here"
                    multiline
                    rows={6}
                    placeholder="We Value Our Customer."
                    name="Customer Form"
                  />
                  </div>
                  <input type="hidden" name="_subject" value="Customer Form Submission" />
                </fieldset>
                <PrimaryButton type="submit">Submit</PrimaryButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
